import { Paper, Tab, Tabs, Typography } from '@material-ui/core'
import React from 'react'
import IQACForm from './IQACComponent/IQACForm'
import Stretegies from './IQACComponent/Stretegies'
import FileGroup from './FileGroupContainer/FileGroup'
import Group from './GroupContainer/Group'
import SubGroup from './SubGroupContainer/SubGroup'
import SubSubGroup from './SubSubGroupContainer/SubSubGroup'
import { Tabs as Tabs1, Tab as Tab1 } from "react-bootstrap";
import SsrGroup from "./SsrGroupContainer/SsrGroup";
import SsrSubGroup from "./SsrSubGroupContainer/SsrSubGroup";
import SsrSubSubGroup from "./SsrSubSubGroupContainer/SsrSubSubGroup";
import SsrFileGroup from "./SsrFileGroupContainer/SsrFileGroup";

const smallTab = [
  {
    title: "IQAC Notifications",
    active: 0,
  },
  {
    title: "AQAR NAAC Matrices",
    active: 1,
  },
  {
    title: "SSR Matrices",
    active: 2,
  },
];

export default function IqacLanding({ apikey, username, role, history }) {
  const [value, setValue] = React.useState(0);
  const [valueSsr, setValueSsr] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSsr = (event, newValue) => {
    setValueSsr(newValue);
  };

  const [active, setActive] = React.useState(0);

  return (
    <div>
      <Typography gutterBottom variant="h6">
        IQAC
      </Typography>

      <br />

      <Tabs1 activeKey={active} onSelect={(k) => setActive(k)}>
        {smallTab.map((el, index) => (
          <Tab1 key={index} eventKey={index} title={el.title}>
            <br />

            {active == 0 ? <IQACForm apikey={apikey} /> : null}

            {active == 1 && (
              <>
                <Paper square>
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label=""
                  >
                    <Tab label="Group" />
                    <Tab label="Sub-Group" />
                    <Tab label="Sub-Sub-Group" />
                    <Tab label="Files" />
                  </Tabs>
                </Paper>

                <div style={{ paddingTop: 10 }}>
                  {value == 0 && <Group apikey={apikey} />}
                  {value == 1 && <SubGroup apikey={apikey} />}
                  {value == 2 && <SubSubGroup apikey={apikey} />}
                  {value == 3 && <FileGroup apikey={apikey} />}
                </div>
              </>
            )}

            


            {active == 2 && (
              <>
                <Paper square>
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangeSsr}
                    aria-label=""
                  >
                    <Tab label="Group" />
                    <Tab label="Sub-Group" />
                    <Tab label="Sub-Sub-Group" />
                    <Tab label="Files" />
                  </Tabs>
                </Paper>

                <div style={{ paddingTop: 10 }}>
                  {valueSsr == 0 && <SsrGroup apikey={apikey} />}
                  {valueSsr == 1 && <SsrSubGroup apikey={apikey} />}
                  {valueSsr == 2 && <SsrSubSubGroup apikey={apikey} />}
                  {valueSsr == 3 && <SsrFileGroup apikey={apikey} />}
                </div>
              </>
            )}
          </Tab1>
        ))}
      </Tabs1>

  

      {/* <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label=""
        > 
          <Tab label="Stretegies" />
          <Tab label="Functions" />
          <Tab label="IQAC Teams" />
          <Tab label="SSR" />
          <Tab label="AQAR" />
          <Tab label="Action Taken Report" />
          <Tab label="Meeting Minutes" />
          <Tab label="Feedback Form" />
          <Tab label="Feedback Analysis" />
          <Tab label="Student Satisfactory Survey" />
          <Tab label="Event/News" />
        </Tabs>
      </Paper>

      <div style={{ paddingTop: 10 }}>
        {value == 0 && <IQACForm type="IQAC_STRATEGIES" title="IQAC Stretegies" apikey={apikey} />}
        {value == 1 && <IQACForm type="IQAC_FUNCTIONS" title="Functions" apikey={apikey} />}
        {value == 2 && <IQACForm type="IQAC_TEAMS" title="IQAC Teams" apikey={apikey} />}
        {value == 3 && <IQACForm type="IQAC_SSR" title="Self Study Report" apikey={apikey} />}
        {value == 4 && <IQACForm type="IQAC_AQAR" title="AQAR" apikey={apikey} />}
        {value == 5 && <IQACForm type="IQAC_ATR" title="Action Taken Report" apikey={apikey} />}
        {value == 6 && <IQACForm type="IQAC_MINUTES" title="Meeting Minutes" apikey={apikey} />}
        {value == 7 && <IQACForm type="IQAC_FEEDBACK_FORM" title="Feedback Form" apikey={apikey} />}
        {value == 8 && <IQACForm type="IQAC_FEEDBACK_ANALYSIS" title="Feedback Analysis" apikey={apikey} />}
        {value == 9 && <IQACForm type="IQAC_SSR" title="Student Satisfactory Survey" apikey={apikey} />}
        {value == 10 && <IQACForm type="IQAC_EVENT" title="Event/News" apikey={apikey} />}
      </div> */}




    </div>
  )
}


