export const SERVER_UPLOAD = process.env.NODE_ENV === 'production' ? 'https://webbackendpandu.college.corexx.in/src/uploads' : 'http://localhost/pandu-website-new/api/src/uploads';



export const Q_LIST = [
    "2010-1st",
    "2010-2nd",
    "2010-3rd",
    "2010-4th",
    "2011-1st",
    "2011-2nd",
    "2011-3rd",
    "2011-4th",
    "2012-1st",
    "2012-2nd",
    "2012-3rd",
    "2012-4th",
    "2013-1st",
    "2013-2nd",
    "2013-3rd",
    "2013-4th",
    "2014-1st",
    "2014-2nd",
    "2014-3rd",
    "2014-4th",
    "2015-1st",
    "2015-2nd",
    "2015-3rd",
    "2015-4th",
    "2016-1st",
    "2016-2nd",
    "2016-3rd",
    "2016-4th",
    "2017-1st",
    "2017-2nd",
    "2017-3rd",
    "2017-4th",
    "2018-1st",
    "2018-2nd",
    "2018-3rd",
    "2018-4th",
    "2019-1st",
    "2019-2nd",
    "2019-3rd",
    "2019-4th",
    "2020-1st",
    "2020-2nd",
    "2020-3rd",
    "2020-4th",
    "2021-1st",
    "2021-2nd",
    "2021-3rd",
    "2021-4th",
    "2022-1st",
    "2022-2nd",
    "2022-3rd",
    "2022-4th",
]