import React, { Component } from 'react';
import { Typography, Grid, Table, TableHead, TableCell, TableRow, TableBody, Chip } from '@material-ui/core';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { Button, FormControl, Card } from 'react-bootstrap';
import { PostData, GetData, DeleteData, PutData } from '../../api/service';
import Snack from '../Snackbar/Snack';
import FileUpload from '../AddNew/FileUpload';
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css


class CollegeEvent extends Component {
    state = {
        id: '',
        edit: false,
        title: '',
        date: '',
        opening_date: '',
        closing_date: '',
        is_dept: 0,
        type: '',
        description: '',
        upload_info: [],
        data: [],
        isDataLoaded: false,
        dept_code: this.props.dept_code,
        message: '',
        open: false,
        loadFile: true
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    __getDeptData = () => {
        let type = '';

        type = 'NONE';

        GetData(`/${this.props.dept_code}/${type}/getnoticesbytypedept`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    data: resp,
                    isDataLoaded: true
                })
            })
    }

    componentDidMount() {
        this.__getDeptData();

    }


    onSubmit = (e) => {
        e.preventDefault();

        let d = {
            id: this.state.id,
            title: this.state.title,
            is_dept: this.state.is_dept,
            posted_as: this.props.dept_name,
            type: this.state.type,
            date: this.state.date,
            opening_date: this.state.opening_date,
            closing_date: this.state.closing_date,
            description: this.state.description,
            upload_info: this.state.upload_info,
            dept_code: this.props.dept_code

        }

        if (this.state.edit) {
            PutData(`/admin/${this.state.id}/editnotices`, d)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        message: resp,
                        open: true
                    }, () => this.__getDeptData())
                    this.cancelEdit();
                })
        } else {
            PostData(`/admin/createnotice`, d)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        message: resp,
                        open: true
                    }, () => this.__getDeptData())
                    this.cancelEdit();
                })
        }


    }

    onDeleteClick = (el) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: this.onDeleteConfirm.bind(this, el.id)
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    onDeleteConfirm = (id) => {
        DeleteData(`/${this.props.apikey}/${id}/deletenotice`)
            .then((resp) => {
                this.setState({
                    message: resp,
                    open: true
                }, () => this.__getDeptData())
            })
    }

    onEditClick = (el) => {
        this.setState({
            ...el,
            edit: true,
            loadFile: false
        }, () => {
            this.setState({
                loadFile: true
            })
        })
    }

    cancelEdit = () => {
        this.setState({
            id: '',
            title: '',
            description: '',
            date: '',
            opening_date: '',
            closing_date: '',
            upload_info: [],
            edit: false,
            loadFile: false
        }, () => {
            this.setState({
                loadFile: true
            })
        })
    }


    render() {
        return (
            <div>

                {this.state.open ? (
                    <Snack
                        open={this.state.open}
                        message={this.state.message}
                        handleClose={() => {
                            this.setState({
                                open: false,
                                message: ''
                            })
                        }}
                    />
                ) : null}

                <form onSubmit={this.onSubmit}>
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        Notifications / Events / Downloads
                    </Typography>


                    <Card>

                        <Card.Body>


                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Type</label>
                                    <FormControl
                                        as="select"
                                        name="type"
                                        required
                                        onChange={this.onChange}
                                        value={this.state.type}
                                    >
                                        <option value="">Select</option>
                                        <option value="COLLEGE_EVENT">News / Event</option>
                                        <option value="COLLEGE_ADMIN_NOTICE">Administrative Notice /  Announcements</option>
                                        <option value="COLLEGE_ACADEMIC_NOTICE">Academic Notice (Student Notification)</option>
                                        <option value="COLLEGE_ACHIEVEMENTS">Recent Achievements</option>
                                        <option value="COLLEGE_DOWNLOADS">Downloads</option>
                                        <option value="COLLEGE_DOWNLOADS_GALLERY">Document Gallery</option>
                                        <option value="COLLEGE_TENDER">College Tender</option>
                                        <option value="COLLEGE_EMP_ACHIEVEMENTS">Employee Achievements</option>
                                        <option value="COLLEGE_STU_ACHIEVEMENTS">Student Achievements</option>
                                        <option value="COLLEGE_PUBLICATIONS">College Publications</option>
                                        <option value="COLLEGE_DEPT_PUBLICATIONS">Departmental Achievements</option>
                                        <option value="NIRF">NIRF DATA</option>
                                        <option value="AISHE">AISHE DATA</option>
                                        <option value="CLASS_ROUTINES">Class Routine</option>
                                        <option value="EXAM_RESULTS">Exam Results</option>
                                        <option value="EXAM_SCHEDULE">Exam Schedule</option>
                                        {/* <option value="STUDENT_CLUBS">Student Clubs</option> */}
                                        <option value="COLLEGE_RESEARCH">College Research</option>
                                        <option value="COLLEGE_CERTIFICATES">College Certificates</option>
                                        <option value="COLLEGE_IDP">Institutional Development Plan</option>
                                        <option value="COLLEGE_PPD">Perspective Plan & Development</option>
                                        <option value="COLLEGE_GOVT_DOC">Government Documents</option>
                                        <option value="FYUGP_ENROLLMENT">FYUGP Enrollment</option>
                                        <option value="COLLEGE_CLUB">Clubs</option>
                                        <option value="COLLEGE_STUDENT_PLACED">List of Students Placed</option>
                                        <option value="COLLEGE_STUDENT_PROGRESSED">List of Students Progressed</option>
                                        
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Date</label>
                                    <FormControl
                                        as="input"
                                        type="date"
                                        name="date"
                                        required
                                        onChange={this.onChange}
                                        value={this.state.date}
                                    />
                                </Grid>

                                {this.state.type == "COLLEGE_TENDER" && <><Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Starting Date</label>
                                    <FormControl
                                        as="input"
                                        type="date"
                                        name="opening_date"
                                        required
                                        onChange={this.onChange}
                                        value={this.state.opening_date}
                                        placeholder="Starting Date"
                                    />
                                </Grid>

                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <label>Ending Date</label>
                                        <FormControl
                                            as="input"
                                            type="date"
                                            name="closing_date"
                                            required
                                            onChange={this.onChange}
                                            value={this.state.closing_date}
                                            placeholder="Ending Date"
                                        />
                                    </Grid></>}



                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <label>Title</label>
                                    <FormControl
                                        as="input"
                                        name="title"
                                        required
                                        onChange={this.onChange}
                                        value={this.state.title}
                                        placeholder="Title of the event"
                                    />
                                </Grid>



                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <label>Description</label>
                                    <ReactQuill
                                        placeholder="Type something here"
                                        value={this.state.description}
                                        onChange={this.handleChange.bind(this, 'description')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Upload Pictures/ Files</label>
                                    {this.state.loadFile ? (<FileUpload
                                        dp={this.state.upload_info}
                                        type={this.state.type}
                                        setDp={this.handleChange.bind(this, 'upload_info')}
                                    />) : null}
                                </Grid>


                            </Grid>



                            {this.state.edit ? (
                                <div align="right">
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        type="submit"
                                    >
                                        Update
                                    </Button>
                                    &nbsp;
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        type="button"
                                        onClick={this.cancelEdit}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            ) : (<div align="right">
                                <Button
                                    size="sm"
                                    variant="info"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div>)}

                        </Card.Body>

                    </Card>

                    <br />


                    <Card>

                        <Card.Body>
                            {this.state.isDataLoaded ? (
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell width="30%">Type</TableCell>
                                                <TableCell width="30%">Title</TableCell>
                                                <TableCell wodth='20%'>Date</TableCell>
                                                <TableCell wodth='20%'>Starting & Endind Date</TableCell>
                                                <TableCell>Edit</TableCell>
                                                <TableCell>Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.data.map((el, index) =>

                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{el.type}</TableCell>
                                                    <TableCell>{el.title}</TableCell>
                                                    <TableCell>{el.date}</TableCell>
                                                    <TableCell>
                                                        {el.opening_date} to
                                                        {el.closing_date}
                                                    </TableCell>


                                                    <TableCell>
                                                        {Array.isArray(el.upload_info) && el.upload_info.map((el1, index1) =>
                                                            <Chip
                                                                key={index1}
                                                                label={el1.path}
                                                            />
                                                        )}
                                                    </TableCell>

                                                    <TableCell><Edit onClick={this.onEditClick.bind(this, el)} /></TableCell>
                                                    <TableCell><Delete onClick={this.onDeleteClick.bind(this, el)} /></TableCell>
                                                </TableRow>

                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            ) : <Typography>
                                Loading . . .
                            </Typography>}
                        </Card.Body>

                    </Card>

                </form>
            </div>
        );
    }
}


export default CollegeEvent;